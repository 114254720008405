import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import './HistoryInnovation.scss';

const data = [
  {
    id: 1,
    year: '1999',
    data: [{
      name: 'SMS-banking',
      place: 'World'
    }]
  },
  {
    id: 2,
    year: '2000',
    data: [{
      name: 'E-vouchers',
      place: 'World'
    }, {
      name: 'OTP',
      place: 'World'
    }, {
      name: 'Call-center',
    }]
  },
  {
    id: 3,
    year: '2001',
    data: [{
      name: 'Internet-banking Privat24 Web',
      place: 'Eastern Europe'
    }]
  },
  {
    id: 4,
    year: '2002',
    data: [{
      name: 'Maestro Prepaid',
      place: 'World'
    }]
  },
  {
    id: 5,
    year: '2003',
    data: [{
      name: 'P2P transfers',
      place: 'World'
    }, {
      name: 'Internet Cards',
      place: 'World'
    }]
  },
  {
    id: 6,
    year: '2005',
    data: [{
      name: 'Site Heart',
      place: 'Messenger with forms'
    }]
  },
  {
    id: 7,
    year: '2007',
    data: [{
      name: 'LiqPay',
      place: 'Internet p2p payments'
    }]
  },
  {
    id: 8,
    year: '2008',
    data: [{
      name: 'Skype Vouchers',
      place: 'Bank partner'
    }]
  },
  {
    id: 9,
    year: '2009',
    data: [{
      name: 'Coinless technology',
      place: 'World'
    }]
  },
  {
    id: 10,
    year: '2010',
    data: [{
      name: 'Internet Banking Privat24 for smartphone',
      place: 'World'
    }, {
      name: 'Open API',
      place: 'World'
    }]
  },
  {
    id: 11,
    year: '2011',
    data: [{
      name: 'mobile POS',
      place: 'Europe'
    }]
  },
  {
    id: 12,
    year: '2012',
    data: [{
      name: 'Contactless cash',
      place: 'World'
    }, {
      name: 'Smart full up',
      place: 'World'
    }]
  },
  {
    id: 13,
    year: '2013',
    data: [{
      name: 'Topless ATM',
      place: 'World Android ATM'
    }, {
      name: 'Cloud HCE',
      place: 'World'
    }]
  },
  {
    id: 14,
    year: '2014-2015',
    data: [{
      name: 'corezoid.com',
      place: 'World'
    }, {
      name: 'deepmemo.ai',
      place: 'World'
    }, {
      name: 'sender.mobi',
      place: 'World'
    }]
  },
  {
    id: 15,
    year: '2016',
    data: [{
      name: 'share.creditcard',
      place: 'World'
    }]
  },
  {
    id: 16,
    year: '2017',
    data: [{
      name: 'trigger.cards',
      place: 'World'
    }]
  },
  {
    id: 17,
    year: '2018',
    data: [{
      name: 'control.events',
      place: 'World'
    }]
  },
  {
    id: 18,
    year: '2019',
    data: [{
      name: 'run.actor',
      place: 'World'
    }]
  },
];

class HistoryInnovation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      positionId: 1
    };
  }

  componentDidMount() {
    this.stopSlider();
    this.startSlider();
  }

  startSlider() {
    this.interval = setInterval(() => {
      const { positionId } = this.state;
      const newPositionId = data.length <= positionId ? 1 : positionId + 1;
      this.setState({
        positionId: newPositionId
      });
    }, 4000);
  }

  stopSlider() {
    clearInterval(this.interval);
    this.interval = null;
  }

  componentWillUnmount() {
    this.stopSlider();
  }

  clickItem(id) {
    this.setState({
      positionId: id
    });
  }

  render() {
    const { intl } = this.props;
    const { positionId } = this.state;
    let positionTop = 'calc(50% - 100px + 34px)';
    let positionBottom = 'calc(50% - 162px)';
    if (this.sliderWrap) {
      positionTop = `${this.sliderWrap.clientWidth / 2 - positionId * 115 + 48}px`;
      positionBottom = `${this.sliderWrap.clientWidth / 2 - positionId * 333 + 170}px`;
    }
    return (
      <div className="historyInnovation">
        <div className="historyInnovation__wrap">
          <div className="historyInnovation__titleWrap">
            <div className="historyInnovation__title">
              {intl.formatMessage({id: 'historyInnovationTitle'})}
            </div>
            <div className="historyInnovation__subTitle">
              {intl.formatMessage({id: 'historyInnovationSubTitle'})}
            </div>
          </div>
          <div className="historyInnovation__slider" ref={el => (this.sliderWrap = el)}>
            <div
              className="historyInnovation__slider__top"
              style={{left: positionTop}}
            >
              {data.map(item => (
                <div
                  key={item.id}
                  className={cn({active: item.id === positionId})}
                  onClick={() => this.clickItem(item.id)}
                >
                  {item.year}
                </div>
              ))}
            </div>
            <div
              className="historyInnovation__slider__bottom"
              style={{left: positionBottom}}
            >
              {data.map(item => (
                <div
                  key={item.id}
                  className="historyInnovation__slider__bottom__item__wrap"
                  onClick={() => this.clickItem(item.id)}
                >
                  {item.data.map(i => (
                    <div
                      className="historyInnovation__slider__bottom__item"
                    >
                      <div className="historyInnovation__slider__bottom__text">
                        <div className="historyInnovation__slider__bottom__title">{i.name}</div>
                        <div className="historyInnovation__slider__bottom__place">
                          <div>🥇</div>
                          {i.place}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HistoryInnovation.propTypes = {
  intl: PropTypes.object,
  // background: PropTypes.string,
};


export default injectIntl(HistoryInnovation);
