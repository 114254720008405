import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './About.scss';
import { Link } from '../../i18n';
import Utils from '../../utils/utils';
import Form from '../../components/Form';
import Image from '../../components/Image/Image';
import HistoryInnovation from '../../components/HistoryInnovation';

class About extends PureComponent {
  render() {
    const { intl, data } = this.props;

    return (
      <div>
        <div className="about__wrapper">
          <div className="grid-wrapper about">
            <div className="col-7 about__text">
              <div className="about__title">
                <h1>
                  {intl.formatMessage({id: data.header.title})}
                </h1>
              </div>
              <div className="about__subTitle">
                <span
                  dangerouslySetInnerHTML={{
                    __html: Utils.bbCodeToHtml(intl.formatMessage({id: data.header.subTitle}))
                  }}
                />
              </div>
            </div>
            <div className="col-5 about__image">
              <Form data={data.header.form} />
            </div>
          </div>
        </div>
        <div className="about__company">
          <div className="about__company__title">
            {intl.formatMessage({id: data.company.title})}
          </div>
          <div className="about__company__subTitle">
            {intl.formatMessage({id: data.company.subTitle})}
          </div>
          <div className="about__company__list">
            {data.company.list.map((item, index) => (
              <div className={`about__company__item ${item.replace('clients/', '').replace('.png', '')}`} key={index}>
                <Image file={item}/>
              </div>
            ))}
          </div>
        </div>
        <div className="about__employees__wrapper">
          <div className="about__employees">
            <div className="about__employees__title">
              {intl.formatMessage({id: data.employees.title})}
            </div>
            <div className="grid-wrapper">
              <div className="col-8 about__employees__subTitle">
                {intl.formatMessage({id: data.employees.subTitle})}
              </div>
              <div className="col-4 grid-wrapper about__employees__info">
                {data.employees.info.map((item, index) => (
                  <div className="col-6" key={index}>
                    <div className="about__employees__info__title">{intl.formatMessage({id: item.title})}</div>
                    <div className="about__employees__info__text">{intl.formatMessage({id: item.text})}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="grid-wrapper about__employees__list">
              {data.employees.list.map((item, index) => (
                <div className="col-3 about__employees__item" key={index}>
                  <div className="about__employees__item__img">
                    <Image file={item.image} />
                  </div>
                  <div className="about__employees__item__name">{item.name}</div>
                  <div className="about__employees__item__position">{item.position}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <HistoryInnovation />
        <div className="about__technology">
          <div className="about__technology__header">
            <div>
              <div className="about__technology__title">
                {intl.formatMessage({id: data.technology.title})}
              </div>
              <div className="about__technology__subTitle">
                {intl.formatMessage({id: data.technology.subTitle})}
              </div>
            </div>
            <div className="about__technology__image"><Image file={data.technology.image}/></div>
          </div>
          <div className="grid-wrapper about__technology__list">
            {data.technology.list.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                className={`col-6 about__technology__list__item ${item.event}`}
                event={item.event}
              >
                <Image file={item.image} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
}


About.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(About);

