import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import AboutPage from '../templates/About';

const host = process.env.GATSBY_HOST;

const about = {
  header: {
    title: 'aboutUsTitle',
    subTitle: 'aboutUsSubTitle',
    form: {
      title: 'aboutUsFormTitle',
      type: 'about',
      fields: [
        {
          type: 'firstName',
          label: 'formFirstName',
          value: ''
        },
        {
          type: 'lastName',
          label: 'formLastName',
          value: ''
        },
        {
          type: 'email',
          label: 'formEmail',
          value: ''
        },
        {
          type: 'company',
          label: 'formCompany',
          value: ''
        },
        {
          type: 'interested',
          label: 'formInterested',
          list: [
            {
              key: 'general',
              label: 'formInterestedGeneral',
              value: false,
            },
            {
              key: 'sales',
              label: 'formInterestedSales',
              value: false,
            },
            {
              key: 'billing',
              label: 'formInterestedBilling',
              value: false,
            },
            {
              key: 'marketing',
              label: 'formInterestedMarketing',
              value: false,
            },
          ],
          value: ''
        },
        {
          type: 'message',
          label: 'formMessage',
          value: ''
        },
      ],
      button: {
        text: 'formSend',
        link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
        event: 'send_message'
      }
    }
  },
  company: {
    title: 'aboutUsCompanyTitle',
    subTitle: 'aboutUsCompanySubTitle',
    list: [
      'clients/western-union.png',
      'clients/visa.png',
      'clients/mastercard.png',
      'clients/transferto.png',
      'clients/viber-logo.png',
      'clients/metro.png',
      'clients/xsolla.png',
      'clients/pepsico-logo.png',
      'clients/philip-morris.png',
      'clients/unilever-logo.png',
      // 'clients/ernst-young.png',
      // 'clients/lanit-logo-partner.png',
      'clients/mambu.png',
      'clients/tascombank-logo-partner.png',
      'clients/tsesnabank.jpg',
      'clients/dtek-logo.png',
      // 'clients/sberbank-ukraine.png',
      'clients/ukrnafta.png',
      'clients/pumb.png',
      'clients/aBank.png',
      'clients/privatbank.png',
      'clients/vostok-logo2.png',
      'clients/tech_uklon.png',
      // 'clients/redmadlogo_horizontal_rgb.png',
    ],
  },
  employees: {
    title: 'aboutUsEmployeesTitle',
    subTitle: 'aboutUsEmployeesSubTitle',
    info: [
      {
        title: 'aboutUsEmployeesInfoYearTitle',
        text: 'aboutUsEmployeesInfoYearText'
      },
      {
        title: 'aboutUsEmployeesInfoEmployeesTitle',
        text: 'aboutUsEmployeesInfoEmployeesText'
      }
    ],
    list: [
      {
        name: 'Alexander Vityaz',
        position: 'CEO & Founder',
        image: 'employees/ap.jpg'
      },
      {
        name: 'Olga',
        position: 'Chief Business Development Officer',
        image: 'employees/hudik.png'
      },
      {
        name: 'Denis',
        position: 'Chief Corezoid Engineer',
        image: 'employees/laverov.png'
      },
      {
        name: 'Dmitry',
        position: 'Chief Technology Officer',
        image: 'employees/makarichev.jpg'
      },
      {
        name: 'Serge Danko',
        position: 'Senior Office Manager',
        image: 'employees/danko.png'
      },
      // {
      //   name: 'Denis',
      //   position: 'Chief Quality Officer',
      //   image: 'employees/averin.png'
      // },
      {
        name: 'Karina',
        position: 'Chief Payment Technologist',
        image: 'employees/chibunina.png'
      },
      {
        name: 'Tatiana',
        position: 'Chief Financial Officer',
        image: 'employees/ignatenko.png'
      },
      {
        name: 'Iren Fesko',
        position: 'Marketing Manager',
        image: 'employees/fesko.png'
      }
    ]
  },
  technology: {
    title: 'aboutUsTechnologyTitle',
    subTitle: 'aboutUsTechnologySubTitle',
    image: 'middleware-logo.svg',
    list: [
      {
        image: 'CorezoidProduct.png',
        link: 'https://admin.corezoid.com',
        event: 'corezoid'
      },
      {
        image: 'simulator.svg',
        link: 'https://simulator.company/',
        event: 'simulator'
      }
    ]
  }
};


const About = (props) => (
  <Layout {...props}>
    <AboutPage
      data={about}
    />
  </Layout>
);

export default withIntl(About);

